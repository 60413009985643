import React from "react";
import Button from "../components/Button";
import Input from "../components/Input";
import withAdd from "../components/withAdd";
import handleAdd from "../api/handleAdd";

const CandidateFormComponent = () => {
  const [name, setName] = React.useState("");
  const session = localStorage.getItem("sessionId");

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        await handleAdd("/candidate/create", { name, session }, () =>
          setName("")
        );
      }}
      className="form-content"
    >
      <Input
        type="text"
        placeholder="Enter candidate name"
        name="name"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <Button className="button-content" label="Add" disabled={!name} />
    </form>
  );
};

export default withAdd(CandidateFormComponent);
