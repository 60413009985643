import toast from "react-hot-toast";

const notify = (type, message) => {
  try {
    if (type === "success") {
      toast.success(message);
    } else {
      toast.error(message);
    }
  } catch (error) {
    alert("An unexpected error has occured");
  }
};

export default notify;
