import React from "react";
import { useParams } from "react-router-dom";
import { Container, Box, Typography, Button } from "@mui/material";

const SessionLinkPage = () => {
  const { sessionId } = useParams();
  const sessionLink = `${process.env.REACT_APP_FRONTEND_URL}/session/${sessionId}`;

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 20 }}>
        <Typography sx={{ mb: 4 }} variant="h5" align="center">
          Session Link
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
          Your session has been created successfully! Here is your session link:
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
          <a href={sessionLink} target="_blank" rel="noopener noreferrer">
            {sessionLink}
          </a>
        </Typography>
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            href={sessionLink}
            target="_blank"
          >
            Open Session Link
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SessionLinkPage;
