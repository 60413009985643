import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import Input from "../components/Input";
import API from "../api";
import notify from "../helpers/notify";

const VotingPage = () => {
  const { participantName } = useParams();
  const [candidates, setCandidates] = useState([]);
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();
  const sessionId = localStorage.getItem("sessionId");
  const [state, setState] = React.useState([]);

  useEffect(() => {
    (async () => {
      const response = await API("GET", `/session/${sessionId}`);
      setCandidates(response?.session_?.candidates);
      setQuestions(response?.session_?.questions);
      setState(() =>
        response?.session_?.candidates?.map((candidate) => ({
          id: candidate?._id,
          questions: Object.assign(
            {},
            ...response?.session_?.questions?.map((question) => ({
              [question._id]: "",
            }))
          ),
        }))
      );
    })();
  }, []);

  const handleScoreChange = (event) => {
    const { id, value, name } = event.target;
    const idParts = id.split("-");
    const candidateId = idParts[0];
    setState((prevState) => {
      const candidate = prevState?.find((item) => item?.id === candidateId);
      if (candidate?.questions) {
        candidate.questions[name] = value;
      }

      return prevState?.map((item) => {
        if (item?.id === candidate?.id) {
          return candidate;
        }

        return item;
      });
    });
  };

  const handleSubmitScore = async (question, candidate, score) => {
    const response = await API("POST", "/score/create", {
      question,
      participant: participantName,
      score,
      candidate,
      session: sessionId,
    });
    if (response?.error) {
      notify("error", response?.error);
    } else {
      notify("success", response?.message);
    }
  };

  const handleFinish = () => {
    navigate("/thanks-for-voting", { replace: true });
  };

  return (
    <>
      {candidates.length > 0 && questions.length > 0 ? (
        <Container maxWidth="md">
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Vote on Candidates
            </Typography>
            {candidates.map((candidate, position) => (
              <Card key={candidate._id} sx={{ mb: 3 }}>
                <CardHeader title={candidate.name} />
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: 5 }}
                >
                  {questions.map((question) => (
                    <Grid container spacing={2} key={question._id}>
                      <Grid item xs={8}>
                        <Typography variant="body1">
                          {question.question}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Input
                          type="number"
                          placeholder="Score"
                          value={
                            state[position]?.questions?.[question?._id] || ""
                          }
                          onChange={handleScoreChange}
                          id={`${candidate?._id}-${position}`}
                          name={question?._id}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleSubmitScore(
                              question._id,
                              candidate?._id,
                              +state[position]?.questions?.[question?._id]
                            )
                          }
                          sx={{ padding: 1 }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                </CardContent>
              </Card>
            ))}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleFinish}
              fullWidth
              sx={{ mt: 4, mb: 5 }}
            >
              Finish
            </Button>
          </Box>
        </Container>
      ) : null}
    </>
  );
};

export default VotingPage;
