import React from "react";
import { Container, Box, Typography } from "@mui/material";

const ThanksForVotingPage = () => {
  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 20, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          Thank You for Voting!
        </Typography>
        <Typography variant="body1">
          Your responses have been recorded. We appreciate your participation.
        </Typography>
      </Box>
    </Container>
  );
};

export default ThanksForVotingPage;
