import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

import Home from "./pages/Home";
import AddParticipants from "./pages/AddParticipants";
import AddCandidates from "./pages/AddCandidates";
import AddQuestions from "./pages/AddQuestions";
import CreateSession from "./pages/CreateSession";
import SessionLinkPage from "./pages/SessionLinkPage";
import SelectParticipantsPage from "./pages/SelectParticipantsPage";
import VotingPage from "./pages/VotingPage";
import ThanksForVotingPage from "./pages/ThanksForVotingPage";
import "./App.css";
import { Toaster } from "react-hot-toast";

function App() {
  const navigate = useNavigate();
  const sessionId = localStorage.getItem("sessionId");

  return (
    <>
      <div className="App">
        <Container sx={{ mt: 4 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/create-session" element={<CreateSession />} />
            <Route
              path="/add-participants"
              element={
                <AddParticipants
                  title="Add Participants"
                  handleContinue={() => navigate("/add-candidates")}
                />
              }
            />
            <Route
              path="/add-candidates"
              element={
                <AddCandidates
                  title="Add Candidates"
                  handleContinue={() => navigate("/add-questions")}
                />
              }
            />
            <Route
              path="/add-questions"
              element={
                <AddQuestions
                  title="Add Questions"
                  handleContinue={() => navigate(`/session-link/${sessionId}`)}
                />
              }
            />
            <Route
              path="/session-link/:sessionId"
              element={<SessionLinkPage />}
            />
            <Route
              path="/session/:sessionId"
              element={<SelectParticipantsPage />}
            />
            <Route
              path="/next-step/:participantName"
              element={<VotingPage />}
            />
            <Route
              path="/thanks-for-voting"
              element={<ThanksForVotingPage />}
            />
          </Routes>
        </Container>
      </div>
      <Toaster />
    </>
  );
}

export default App;
