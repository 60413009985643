import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import API from "../api";
import notify from "../helpers/notify";

const CreateSession = () => {
  const [sessionName, setSessionName] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSessionName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await API("POST", "/session/create", { sessionName });
    if (response?.error) {
      notify("error", response?.error);
    } else {
      notify("success", response?.message);
      localStorage.setItem("sessionId", response?._id);
      navigate("/add-participants", { replace: true });
      setSessionName("");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 20 }}>
        <Typography sx={{ mb: 4 }} variant="h5" align="center">
          Create Session
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Session Name"
                variant="outlined"
                name="sessionName"
                size="small"
                value={sessionName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6} sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default CreateSession;
