import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Button, Stack } from "@mui/material";

function Home() {
  return (
    <Container className="button-container" sx={{ mt: 30 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Welcome, Admin
      </Typography>{" "}
      <Stack
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/create-session"
        >
          Create Session
        </Button>
      </Stack>
    </Container>
  );
}

export default Home;
