import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import API from "../api";
import notify from "../helpers/notify";

const SelectParticipantPage = () => {
  const { sessionId } = useParams();
  const [participants, setParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState("");
  const [participant, setParticipant] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const response = await API("GET", `/session/${sessionId}`);
      setParticipants(response?.session_?.participants);
    })();
  }, []);

  const handleChange = (event) => {
    setSelectedParticipant(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectedParticipant) {
      localStorage.setItem("selectedParticipant", participant);
      navigate(`/next-step/${participant}`);
    } else {
      notify("error", "Please select a participant.");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 20 }}>
        <Typography sx={{ mb: 4 }} variant="h5" align="center">
          Select Participant
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }} size="small">
            <InputLabel>Select Participant</InputLabel>
            <Select
              value={selectedParticipant}
              onChange={handleChange}
              label="Select Participant"
              sx={{ textAlign: "left" }}
            >
              {participants.map((participant, index) => (
                <MenuItem
                  key={index}
                  value={participant?.name}
                  onClick={() => setParticipant(participant?._id)}
                >
                  {participant?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Continue
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default SelectParticipantPage;
