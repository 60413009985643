import API from ".";
import notify from "../helpers/notify";

const handleAdd = async (url, data, clear) => {
  const response = await API("POST", url, data);
  if (response?.error) {
    notify("error", response?.error);
    return;
  } else {
    notify("success", response?.message);
    clear();
  }
};

export default handleAdd;
