import React from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import withAdd from "../components/withAdd";
import handleAdd from "../api/handleAdd";

const ParticipantFormComponent = () => {
  const [participant, setParticipant] = React.useState("");
  // const [loading, setLoading] = React.useState(false);
  const session = localStorage.getItem("sessionId");

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        await handleAdd(
          "/participant/create",
          {
            name: participant,
            session,
          },
          () => setParticipant("")
        );
      }}
      className="form-content"
    >
      <Input
        placeholder="Enter participant name"
        name="participant"
        type="text"
        value={participant}
        onChange={(event) => setParticipant(event.target.value)}
        required
      />
      <Button
        type="submit"
        className="button-content"
        label="Add"
        disabled={!participant}
      />
    </form>
  );
};

export default withAdd(ParticipantFormComponent);
