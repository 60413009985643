import { Box, Container, Typography } from "@mui/material";
import Button from "./Button";

const withAdd = (WrappedComponent) => {
  let NewComponent;

  return (NewComponent = (props) => {
    return (
      <Container maxWidth="sm">
        <Box sx={{ mt: 20 }}>
          <Typography sx={{ mb: 4 }} variant="h5" align="center">
            {props.title}
          </Typography>
          <WrappedComponent {...props} />
          <Button
            className="button-content next-btn"
            label="Continue"
            onClick={props.handleContinue}
          />
        </Box>
      </Container>
    );
  });
};

export default withAdd;
