import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const API = async (method, url, data, headers) => {
  try {
    const response = await axios.request({
      url,
      method,
      data,
      headers: { ...headers },
    });

    return response?.data;
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
    if (error?.data) {
      return error?.data;
    }

    return error;
  }
};

export default API;
