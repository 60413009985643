import React from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import withAdd from "../components/withAdd";
import handleAdd from "../api/handleAdd";

const QuestionFormComponent = () => {
  const [question, setQuestion] = React.useState("");
  const session = localStorage.getItem("sessionId");

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        await handleAdd("/question/create", { question, session }, () =>
          setQuestion("")
        );
      }}
      className="form-content"
    >
      <Input
        placeholder="Enter question"
        name="question"
        value={question}
        type="text"
        onChange={(event) => setQuestion(event.target.value)}
      />
      <Button className="button-content" label="Add" disabled={!question} />
    </form>
  );
};

export default withAdd(QuestionFormComponent);
